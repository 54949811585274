import { useState, useEffect } from 'react';

export const useApiCaller = (api, defaultValue) => {
    const [resp, setResp] = useState(defaultValue);
    const [error, setError] = useState({});
    const [isApiInProgress, setIsLoading] = useState(true);
    const handleFetchApi = async () => {
        try {
            const resp = await api();
            setError({});
            setResp(resp);
        } catch (e) {
            console.log(e, '>>>>e<<<<');
            setResp(defaultValue);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handleRefetch = handleFetchApi;

    useEffect(() => {
        if (api) {
            handleFetchApi();
        }
    }, []);
  return {data: resp?.data || defaultValue, error, isApiInProgress, handleRefetch};
};

export default useApiCaller;
