import ReactDOM from "react-dom";
import "src/utils/chart";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "src/hooks/useScrollTop";
import "nprogress/nprogress.css";
import { Provider } from "react-redux";
import store from "src/store";
import App from "src/App";
import { SidebarProvider } from "src/contexts/SidebarContext";
import * as serviceWorker from "src/serviceWorker";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import reportWebVitals from "./reportWebVitals";
import { ClientToken } from "./mrkt365config";
import axios from "axios";

// the below header will be injected to every axios req
// TODO: Only add this in req made to MRKT365 APIs
axios.defaults.headers.common['client-token'] = ClientToken;

ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
            <SidebarProvider>
                <BrowserRouter>
                    <ScrollTop />
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </SidebarProvider>
        </Provider>
    </HelmetProvider>,
    document.getElementById("root")
);

reportWebVitals(console.log);
serviceWorker.unregister();
