export const env = process.env.NODE_ENV

const host =
    window.location.host === "superadmin.mrkt365.com"
        ? "qa-api"
        : window.location.host === "superadmin.mrkt365.com"
            ? "api"
            : "qa-api";

const localHost = "https://apiprod.mrkt365.com/";
// const localHost = "http://localhost/";
const HOST_URL = "https://" + host + ".platform.mrkt365.com/";

export const CURRENT_URL = (env === "development" ? "http://" : "https://") + window.location.host + "/";

export const PLATFORM_URL = (env === "development" ? "http://" : "https://") + (host === "api" ? "platform.mrkt365.com" : "platform.mrkt365.com") + "/"

// mrkt365user
const DEV_MRKT365User_Endpoint = localHost + "mrkt365user/api/v1/";
const MRKT365User_Endpoint = HOST_URL + "mrkt365user/api/v1/";

// mrkt365vendor
const DEV_MRKT365Vendor_Endpoint = localHost + "mrkt365vendor/api/v1/";
const MRKT365Vendor_Endpoint = HOST_URL + "mrkt365vendor/api/v1/";

// mrkt365project
const DEV_MRKT365Project_Endpoint = localHost + "mrkt365project/api/v1/";
const MRKT365Project_Endpoint = HOST_URL + "mrkt365project/api/v1/";

// mrkt365payment
const DEV_MRKT365Payment_Endpoint = localHost + "mrkt365payment/api/v1/";
const MRKT365Payment_Endpoint = HOST_URL + "mrkt365payment/api/v1/";

// mrkt365superadmin
const DEV_MRKT365SuperAdmin_Endpoint = localHost + "mrkt365superadmin/api/v1/";
const MRKT365SuperAdmin_Endpoint = HOST_URL + "mrkt365superadmin/api/v1/";

export const userEndpoint = env === "development" ? DEV_MRKT365User_Endpoint : MRKT365User_Endpoint;
export const vendorEndpoint = env === "development" ? DEV_MRKT365Vendor_Endpoint : MRKT365Vendor_Endpoint;
export const projectEndpoint = env === "development" ? DEV_MRKT365Project_Endpoint : MRKT365Project_Endpoint;
export const paymentEndpoint = env === "development" ? DEV_MRKT365Payment_Endpoint : MRKT365Payment_Endpoint;
export const superAdminEndpoint = env === "development" ? DEV_MRKT365SuperAdmin_Endpoint : MRKT365SuperAdmin_Endpoint;

export const taskChatWebsockerUrl = "wss://" + host + ".platform.mrkt365.com/ws/chat/";
export const trueProductionFlag = host === "api" ? true : false;

export const ClientToken = 'nzl5MSP6eW'
