import { lazy } from "react";
import Authenticated from "src/components/Authenticated";

import BaseLayout from "src/layouts/BaseLayout";
import baseRoutes from "./base";
import CollapsedSidebarLayout from "src/layouts/CollapsedSidebarLayout";

import Loader from "../components/Loader";

const LoginPage = Loader(lazy(() => import("../content/login")));
const GoogleLogin = Loader(lazy(() => import("../content/login/googleLogin")));
const Logout = Loader(lazy(() => import("../content/logout")));
const CustomCommission = Loader(lazy(() => import("../content/custom-commission")));
const LogHistory = Loader(lazy(() => import("../content/LogHistory")));
const ImportReviews = Loader(lazy(() => import("../content/import-reviews")));
const DeleteUser = Loader(lazy(() => import("../content/delete-user")));

// analytics dashboard related imports
const AnalyticsDashboard = Loader(lazy(() => import("../content/analytics-dashboard")));
const CountrywiseRegisteredUsersDetailedView = Loader(
    lazy(() => import("../content/analytics-dashboard/CountrywiseRegisteredUsersDetailedView"))
);
const StatisticsPage = Loader(lazy(() => import("../components/blocks/Statistics")));

const ChartsSmall = Loader(lazy(() => import("../components/blocks/ChartsSmall")));
const ChartsLarge = Loader(lazy(() => import("../components/blocks/ChartsLarge")));
const ComposedCards = Loader(lazy(() => import("../components/blocks/ComposedCards")));

// integrations cost analytics
const KeywordRankingCronDailyCostAnalytics = Loader(
    lazy(() => import("src/content/integrations-cost-analytics/KeywordRankingCronDailyCostAnalytics"))
);
const AuditCostAnalytics = Loader(lazy(() => import("src/content/integrations-cost-analytics/AuditCostAnalytics")));

const router = [
    {
        path: "*",
        element: <BaseLayout />,
        children: baseRoutes,
    },
    {
        path: "google/callback",
        children: [
            {
                element: <GoogleLogin />,
            },
        ],
    },
    // Logout
    {
        path: "logout",
        element: <Logout />,
    },
    //custom-commission
    {
        path: "custom-commission",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <CustomCommission />,
            },
        ],
    },
    //log history
    {
        path: "log-history",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <LogHistory />,
            },
        ],
    },
    //import reviews
    {
        path: "import-reviews",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <ImportReviews />,
            },
        ],
    },
    //delete user
    {
        path: "delete-user",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <DeleteUser />,
            },
        ],
    },
    // anaytics dashboard
    {
        path: "analytics-dashboard",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <AnalyticsDashboard />,
            },
        ],
    },
    {
        path: "countrywise-registered-users-detailed-view",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <CountrywiseRegisteredUsersDetailedView />,
            },
        ],
    },
    {
        path: "statistics",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <StatisticsPage />,
            },
        ],
    },
    {
        path: "charts-small",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <ChartsSmall />,
            },
        ],
    },
    {
        path: "charts-large",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <ChartsLarge />,
            },
        ],
    },
    {
        path: "composed-cards",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <ComposedCards />,
            },
        ],
    },
    {
        path: "integrations-cost-analytics",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "keyword-ranking-tasks-cost",
                element: <KeywordRankingCronDailyCostAnalytics />,
            },
            {
                path: "audit-cost",
                element: <AuditCostAnalytics />,
            },
        ],
    },
];

export default router;
