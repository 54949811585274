import { Box, List, ListItem, ListItemText, Menu, MenuItem, styled } from "@mui/material";
import { useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import AuthContext from "src/contexts/JWTAuthContext";
import "./menu-styles.css";

const ListWrapper = styled(Box)(
    ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(["color", "fill"])};
            width: auto;
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 0.3rem;
                            width: 1.5rem;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -0.5rem;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
    const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    const commission_ref = useRef(null);
    const [isCommissionOpen, setCommissionOpen] = useState(false);

    const seo_cost_ref = useRef(null);
    const [isSeoCostOpen, setSeoCostOpen] = useState(false);

    const handleCommissionOpen = () => {
        setCommissionOpen(true);
    };

    const handleCommissionClose = () => {
        setCommissionOpen(false);
    };

    const handleSeoCostOpen = () => {
        setSeoCostOpen(true);
    };

    const handleSeoCostClose = () => {
        setSeoCostOpen(false);
    };

    return (
        <div className="menu-holding-container">
            <ListWrapper>
                <List disablePadding component={Box} display="flex">
                    <ListItem
                        classes={{ root: "MuiListItem-indicators" }}
                        button
                        component={NavLink}
                        to={`/analytics-dashboard`}
                    >
                        <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t("Analytics")} />
                    </ListItem>
                    <ListItem
                        classes={{ root: "MuiListItem-indicators" }}
                        button
                        ref={commission_ref}
                        onClick={handleCommissionOpen}
                    >
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary={
                                <Box display="flex" alignItems="center">
                                    {t("Commissions")}
                                    <Box display="flex" alignItems="center" pl={0.3}>
                                        <ExpandMoreTwoToneIcon fontSize="small" />
                                    </Box>
                                </Box>
                            }
                        />
                    </ListItem>
                    <ListItem
                        classes={{ root: "MuiListItem-indicators" }}
                        button
                        component={NavLink}
                        to={`/import-reviews`}
                    >
                        <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t("Verify import reviews")} />
                    </ListItem>
                    <ListItem
                        classes={{ root: "MuiListItem-indicators" }}
                        button
                        component={NavLink}
                        to={`/delete-user`}
                    >
                        <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t("Delete user")} />
                    </ListItem>
                    <ListItem
                        classes={{ root: "MuiListItem-indicators" }}
                        button
                        ref={seo_cost_ref}
                        onClick={handleSeoCostOpen}
                    >
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary={
                                <Box display="flex" alignItems="center">
                                    {t("Integrations Cost Analytics")}
                                    <Box display="flex" alignItems="center" pl={0.3}>
                                        <ExpandMoreTwoToneIcon fontSize="small" />
                                    </Box>
                                </Box>
                            }
                        />
                    </ListItem>
                </List>

                <Menu
                    disableScrollLock
                    anchorEl={commission_ref.current}
                    onClose={handleCommissionClose}
                    open={isCommissionOpen}
                >
                    <MenuItem component={NavLink} to="/custom-commission" onClick={handleCommissionClose}>
                        {t("Custom commisions")}
                    </MenuItem>
                    <MenuItem component={NavLink} to="/log-history" onClick={handleCommissionClose}>
                        {t("Log History")}
                    </MenuItem>
                </Menu>

                <Menu
                    disableScrollLock
                    anchorEl={seo_cost_ref.current}
                    onClose={handleSeoCostClose}
                    open={isSeoCostOpen}
                >
                    <MenuItem
                        component={NavLink}
                        to="/integrations-cost-analytics/keyword-ranking-tasks-cost"
                        onClick={handleCommissionClose}
                    >
                        {t("Keyword ranking tasks cost")}
                    </MenuItem>
                    <MenuItem
                        component={NavLink}
                        to="/integrations-cost-analytics/audit-cost"
                        onClick={handleCommissionClose}
                    >
                        {t("Audit cost")}
                    </MenuItem>
                </Menu>
            </ListWrapper>
        </div>
    );
}

export default HeaderMenu;
