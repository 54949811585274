import {
    Box,
    Card,
    Typography,
    Container,
    Divider,
    Button,
    FormControl,
    OutlinedInput,
    InputAdornment,
    styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const MainContent = styled(Box)(
    () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
);

const OutlinedInputWrapper = styled(OutlinedInput)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
  `
);

const ButtonSearch = styled(Button)(
    ({ theme }) => `
      margin-right: -${theme.spacing(1)};
  `
);

function Status401() {
    const { t } = useTranslation();

    useEffect(() => setTimeout(console.log.bind(console, '%cYOU ARE NOT A SUPERUSER', 'color: red;font-size: 5rem'), 0))

    return (
        <>
            <Helmet>
                <title>Status - 401</title>
            </Helmet>
            <MainContent>
                <Container maxWidth="md">
                    <Box textAlign="center">
                        <img alt="401" height={180} src="/static/images/status/401.svg" />
                        <Typography
                            variant="h2"
                            sx={{
                                my: 2
                            }}
                        >
                            {t("This site is only intended for verified superadmins.")}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="text.secondary"
                            fontWeight="normal"
                            sx={{
                                mb: 4
                            }}
                        >
                            {t(
                                "You cannot access this site as you are not a superadmin of mrkt365"
                            )}
                        </Typography>
                        <Button variant="outlined" href="/logout" startIcon={<LogoutTwoToneIcon />}>Logout</Button>
                    </Box>
                </Container>
            </MainContent>
        </>
    );
}

export default Status401;
