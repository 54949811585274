/* eslint-disable class-methods-use-this */
class BrowserCommunicator {
	constructor() {
		window.addEventListener('storage', this.message_receive);
	}

	static broadcastLogout = () => {
		localStorage.setItem('logout', 'true');
		localStorage.removeItem('logout');
	};

	static broadcastLogin = () => {
		localStorage.setItem('login', 'true');
		localStorage.removeItem('login');
	};

	message_receive(ev) {
		if (ev.key === 'logout') window.location.href = '/login';
		if (ev.key === 'login') window.location.href = '/dashboard';
	}
}

export default BrowserCommunicator;
