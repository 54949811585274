import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import StarHalfTwoToneIcon from '@mui/icons-material/StarHalfTwoTone';
import AutoGraphTwoToneIcon from '@mui/icons-material/AutoGraphTwoTone';
const superadminMenuItems = [
    {
        heading: "General",
        items: [
            {
                name: "Analytics",
                icon: AutoGraphTwoToneIcon,
                link: "/analytics-dashboard",
            },
            {
                name: 'Commissions',
                icon: AccountTreeTwoToneIcon,
                items: [
                    {
                        name: 'Custom commissions',
                        link: '/custom-commission'
                    },
                    {
                        name: 'Log History',
                        link: '/log-history'
                    }
                ]
            },
            {
                name: "Verify Import Reviews",
                icon: StarHalfTwoToneIcon,
                link: "/import-reviews",
            },
            {
                name: "Delete User",
                icon: DeleteForeverTwoToneIcon,
                link: "/delete-user",
            },
        ],
    },
];

export default superadminMenuItems;
